import React from "react";
import * as styles from "./ContactBar.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

// Fix huge icon flash: https://github.com/FortAwesome/react-fontawesome/issues/234
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

const ContactBar = () => {
  return (
    <div className={styles.Container}>
      <div className={styles.PhoneContainer}>
        <FontAwesomeIcon icon={faPhone} className={styles.Icon} />
        <a
          className={[styles.Phone, styles.Link].join(" ")}
          href="tel:+2348091040408"
          target="_blank"
          rel="noopener noreferrer"
        >
          +234 809 104 0408
        </a>
      </div>
      <div className={styles.EmailContainer}>
        <FontAwesomeIcon icon={faEnvelope} className={styles.Icon} />
        <a
          className={[styles.Phone, styles.Link].join(" ")}
          href="mailto:info@mkwconsultingltd.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@mkwconsultingltd.com
        </a>
      </div>
    </div>
  );
};

export default ContactBar;
