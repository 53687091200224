import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./RealEstateServices.module.scss";

const EstatePlanning = () => {
  return (
    <section className={styles.Container} id="realestateservices">
      <div className={[styles.Row, styles.ReverseWrap].join(" ")}>
        <div className={styles.Left}>
          <h3 className={styles.Title}>Real Estate Services</h3>
          <div className={styles.Text}>
            MKW also advises on real estate projects. We believe in getting things done properly
            which means professionally. From the onset we totally immerse ourselves in your business
            and work within your key objectives.
          </div>
        </div>
        <div className={styles.Right}>
          <StaticImage
            src="../images/tallbuildings.jpg"
            alt="Calculator pen and ink on paper"
            objectFit="scale-down"
            placeholder="none"
            className={styles.Image}
          />
        </div>
      </div>
      <div className={[styles.Row, styles.Wrap].join(" ")}>
        <div className={styles.Left}>
          <StaticImage
            src="../images/flats.png"
            alt="Calculator pen and ink on paper"
            objectFit="scale-down"
            placeholder="none"
            className={styles.Image}
          />
        </div>
        <div className={styles.Right}>
          <div className={[styles.Text, styles.Paragraph].join(" ")}>
            We excel in providing expert management at all stages of real estate development
            projects. From feasibility studies to project execution and project completion, you can
            afford to relax, knowing that we would be there to guide you every step of the way.
          </div>
          <div className={styles.Text}>
            Over the years, our project development team has had extensive experience in delivering
            on multiple projects. From ground leveling to complex redevelopments, we welcome
            challenges of all types as we know we are more than capable of delivering excellent
            quality real estate projects within the time frame expected.
          </div>
        </div>
      </div>
      <div></div>
    </section>
  );
};

export default EstatePlanning;
