import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";
import * as styles from "./Menu.module.scss";

const Menu = () => {
  return (
    <nav className={styles.Container}>
      <AnchorLink to="/#home" title="Home" className={styles.MenuItem}>
        <span>HOME</span>
      </AnchorLink>
      <AnchorLink to="/#services" title="Our services" className={styles.MenuItem}>
        <span>SERVICES</span>
      </AnchorLink>
      <AnchorLink to="/#about" title="About us" className={styles.MenuItem}>
        <span>ABOUT</span>
      </AnchorLink>
      <AnchorLink to="/#team" title="Our team" className={styles.MenuItem}>
        <span>TEAM</span>
      </AnchorLink>
      <AnchorLink to="/#contactus" title="Contact us" className={styles.MenuItem}>
        <span>CONTACT</span>
      </AnchorLink>
    </nav>
  );
};

export default Menu;
