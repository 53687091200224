import React from "react";
import * as styles from "./ContactUs.module.scss";

const ContactUs = () => {
  return (
    <section className={styles.ContactUsContainer} id="contactus">
      <div className={styles.Content}>
        <h3 className={styles.Heading}>CONTACT US</h3>
        <div className={styles.ContactMethods}>
          <div>
            <div className={styles.Label}>Give us a call</div>
            <a
              className={[styles.Text, styles.Link].join(" ")}
              href="tel:+2348091040408"
              target="_blank"
              rel="noopener noreferrer"
            >
              +234 809 104 0408
            </a>
          </div>
          <div>
            <div className={styles.Label}>Email us</div>
            <a
              className={[styles.Text, styles.Link].join(" ")}
              href="mailto:info@mkwconsultingltd.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              info@mkwconsultingltd.com
            </a>
          </div>
          <div>
            <div className={styles.Label}>Opening hours</div>
            <div className={styles.Text}>Mon – Fri, 8am – 5pm</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
