import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./OurTeam.module.scss";

const OurTeam = () => {
  return (
    <section className={styles.Container} id="team">
      <h3 className={styles.Heading}>~ Our Team ~</h3>
      <div className={[styles.Row, styles.Wrap].join(" ")}>
        <div className={styles.Left}>
          <StaticImage
            src="../images/moronke-team-image.jpg"
            alt="Portrait of Moronke Williams"
            objectFit="scale-down"
            placeholder="none"
          />
        </div>
        <div className={styles.Right}>
          <div className={styles.Name}>Moronke Williams</div>
          <div className={styles.Title}>CEO</div>
          <div className={styles.Bio}>
            Moronke Williams is a qualified solicitor who practiced in the UK for several years, and
            later joined the ARM Group as Head ARM Trustees Limited ( one of the foremost trust
            companies in Nigeria)
          </div>
        </div>
      </div>
      <div className={[styles.Row, styles.WrapReverse].join(" ")}>
        <div className={styles.Left}>
          <div className={styles.Name}>Olaseni Williams</div>
          <div className={styles.Title}>DIRECTOR</div>
          <div className={styles.Bio}>
            Olaseni Williams is an experienced corporate and commercial lawyer who has practiced for
            more than 15 years. He has worked on a wide variety of major transactions including
            mergers and acquisitions, corporate restructuring, corporate finance, and stock market
            launches across diverse sectors such as oil and gas, education, aviation, healthcare,
            hospitality, real estate, and banking. Olaseni is qualified to practice law in England
            and Wales, the State of New York, as well as the Federal Republic of Nigeria.
          </div>
        </div>
        <div className={styles.Right}>
          <StaticImage
            src="../images/seni-team-image.jpg"
            alt="Portrait of Olaseni Williams"
            objectFit="scale-down"
            placeholder="none"
          />
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
