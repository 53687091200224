import React from "react";
import * as styles from "./About.module.scss";

const About = () => {
  return (
    <section className={styles.ParallaxContainer} id="about">
      <div className={styles.ParallaxBg}>
        <div className={styles.Parallax} />
      </div>
      <div className={[styles.Content, styles.Overlay].join(" ")}>
        <h3 className={styles.Title}>About</h3>
        <div className={styles.Text}>
          <div className={styles.Left}>
            <p>
              <strong>Let us introduce ourselves!</strong> MKW Consulting Limited(“MKW”), prides
              itself in providing professional advice in establishing the right estate planning
              vehicles for the discerning individuals and their beneficiaries. Advice given is
              specifically tailored towards our clients’ wishes. The end result is a very satisfied
              client who is rest assured that his/her acquired assets are administered and
              distributed to named beneficiaries.
            </p>
          </div>
          <div className={styles.Right}>
            <p>
              The sterling consultancy service MKW has to offer has been made possible through the
              wealth of experience of its team headed by Moronke Williams (Learn more about her in
              the team section.)
            </p>
            <p>
              Our growing team of professionals would work hand in hand with you, our clients, by
              offering comprehensive solutions and sound professional advice. This enables you to be
              better informed and professionally guided in the decision making process.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
