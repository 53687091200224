import React from "react";
import * as styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={styles.FooterContainer}>
      <div className={styles.Copyright}>
        Copyright © {new Date().getFullYear()} MKW Consulting LTD
      </div>
      <div className={styles.DevelopedBy}>
        (Developed and hosted by{" "}
        <a
          className={styles.Link}
          href="https://reformit.solutions"
          target="_blank"
          rel="noopener noreferrer"
        >
          Reform IT Solutions, LLC
        </a>
        )
      </div>
    </footer>
  );
};

export default Footer;
