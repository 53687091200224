import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./EstatePlanning.module.scss";

const EstatePlanning = () => {
  return (
    <section className={styles.Container} id="estateplanning">
      <div className={[styles.Row, styles.ReverseWrap].join(" ")}>
        <div className={styles.Left}>
          <h3 className={styles.Title}>Estate Planning</h3>
          <div className={styles.Text}>
            At MKW, our experienced estate planning consultants, assist clients who require
            comprehensive advice to maximize the value of their estates. An important aspect of
            having wealth is being able to leave behind a lasting financial legacy for loved ones or
            for causes close to one’s heart.
          </div>
        </div>
        <div className={styles.Right}>
          <StaticImage
            src="../images/calculator.jpg"
            alt="Calculator pen and ink on paper"
            objectFit="scale-down"
            placeholder="none"
            className={styles.Image}
          />
        </div>
      </div>
      <div className={[styles.Row, styles.Wrap].join(" ")}>
        <div className={styles.Left}>
          <StaticImage
            src="../images/commercialbuilding.png"
            alt="Calculator pen and ink on paper"
            objectFit="scale-down"
            placeholder="none"
            className={styles.Image}
          />
        </div>
        <div className={styles.Right}>
          <div className={[styles.Text, styles.Paragraph].join(" ")}>
            Whilst working closely with you, we can ensure that your wealth is protected and
            preserved both during your lifetime and after, by putting in place a well-structured
            plan thus ensuring a smooth and methodical transfer of assets to the next generation.
          </div>
          <div className={[styles.Text, styles.Paragraph].join(" ")}>
            Whether it is putting money aside for your child’s education by establishing an
            education trust, or drafting a Will, our estate planning consultant will be with you
            every step of the way, reviewing your estate plan periodically and ensuring it is in
            line with your current financial situation
          </div>
          <div className={styles.Text}>Some estate planning vehicles we advise on are:</div>
          <ul className={styles.Text}>
            <li>Wills</li>
            <li>Trusts</li>
            <li>Holding Companies</li>
            <li>Foundations</li>
          </ul>
        </div>
      </div>
      <div></div>
    </section>
  );
};

export default EstatePlanning;
