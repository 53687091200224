import * as React from "react";
import { graphql } from "gatsby";
import About from "../components/About";
import ContactBar from "../components/ContactBar";
import ContactUs from "../components/ContactUs";
import EstatePlanning from "../components/EstatePlanning";
import HeroSection from "../components/HeroSection";
import NavBar from "../components/NavBar";
import RealEstateServices from "../components/RealEstateServices";
import Seo from "../components/seo";
import Services from "../components/Services";
import Footer from "../components/Footer";
import * as styles from "./index.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import OurTeam from "../components/OurTeam";

// Fix huge icon flash: https://github.com/FortAwesome/react-fontawesome/issues/234
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

const IndexPage = ({ data }) => {
  // determined if page has scrolled and if the view is on mobile
  const [scrolled, setScrolled] = React.useState(false);

  // change state on scroll
  React.useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 500;
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled);
      }
    };

    document.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  return (
    <div id="top">
      <AnchorLink className={[styles.UpButton, scrolled && styles.ShowUp].join(" ")} to="/#top">
        <FontAwesomeIcon icon={faChevronUp} className={styles.UpIcon} />
      </AnchorLink>
      <header>
        <ContactBar />
        <NavBar />
      </header>
      <main>
        <HeroSection />
        <Services />
        <EstatePlanning />
        <RealEstateServices />
        <About />
        <OurTeam />
        <ContactUs />
      </main>
      <Footer />
    </div>
  );
};

export default IndexPage;

export const Head = ({ data }) => {
  const defaultImage =
    data.site.siteMetadata.siteUrl +
    data.defaultImage.childImageSharp.gatsbyImageData.images.fallback.src;
  console.log(defaultImage);

  return <Seo title="MKW Consulting LTD." image={defaultImage} />;
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    defaultImage: file(relativePath: { eq: "socialmedia.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
