// extracted by mini-css-extract-plugin
export var Container = "About-module--Container--u8wZm";
export var Content = "About-module--Content--d7Hfa";
export var Left = "About-module--Left--IBZxz";
export var Overlay = "About-module--Overlay--JPiZl";
export var Parallax = "About-module--Parallax--e6SQq";
export var ParallaxBg = "About-module--ParallaxBg--omZsI";
export var ParallaxContainer = "About-module--ParallaxContainer--G+E96";
export var Right = "About-module--Right--X7I9N";
export var Text = "About-module--Text--my-z0";
export var Title = "About-module--Title--dmxDx";