// extracted by mini-css-extract-plugin
export var Container = "EstatePlanning-module--Container--vcct-";
export var Image = "EstatePlanning-module--Image--Zo8rF";
export var Left = "EstatePlanning-module--Left--FDw2d";
export var Paragraph = "EstatePlanning-module--Paragraph--bVpJG";
export var ReverseWrap = "EstatePlanning-module--ReverseWrap--7qJzB";
export var Right = "EstatePlanning-module--Right--D1aNo";
export var Row = "EstatePlanning-module--Row--luI8v";
export var Text = "EstatePlanning-module--Text--4Ejcp";
export var Title = "EstatePlanning-module--Title--paRA-";
export var Wrap = "EstatePlanning-module--Wrap--mANn2";