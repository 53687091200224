// extracted by mini-css-extract-plugin
export var Container = "RealEstateServices-module--Container--vHaG9";
export var Image = "RealEstateServices-module--Image--8XWC0";
export var Left = "RealEstateServices-module--Left--elziO";
export var Paragraph = "RealEstateServices-module--Paragraph--OsnwQ";
export var ReverseWrap = "RealEstateServices-module--ReverseWrap--uqdLx";
export var Right = "RealEstateServices-module--Right--OWF7k";
export var Row = "RealEstateServices-module--Row--IE9Tn";
export var Text = "RealEstateServices-module--Text--46Qsr";
export var Title = "RealEstateServices-module--Title--a1OPz";
export var Wrap = "RealEstateServices-module--Wrap--BnspN";