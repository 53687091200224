import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Menu from "./Menu/Menu";
import * as styles from "./NavBar.module.scss";

const NavBar = () => {
  return (
    <div className={styles.Container}>
      <StaticImage
        src="../images/icon.png"
        alt="Logo"
        width={115}
        objectFit="scale-down"
        placeholder="none"
        className={styles.Image}
      />
      <Menu />
    </div>
  );
};

export default NavBar;
