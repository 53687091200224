// extracted by mini-css-extract-plugin
export var Bio = "OurTeam-module--Bio--tqMJY";
export var Container = "OurTeam-module--Container--dKPLz";
export var Heading = "OurTeam-module--Heading--aRbtL";
export var Left = "OurTeam-module--Left--5Zeed";
export var Name = "OurTeam-module--Name--NiH7u";
export var Right = "OurTeam-module--Right--6LnAL";
export var Row = "OurTeam-module--Row--vyL+p";
export var Title = "OurTeam-module--Title--oOSfH";
export var Wrap = "OurTeam-module--Wrap--axTVy";
export var WrapReverse = "OurTeam-module--WrapReverse--28QSX";