import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./HeroSection.module.scss";

const HeroSection = () => {
  return (
    <section className={styles.Container} id="home">
      <div className={styles.Overlay}>
        <div className={styles.Pane}>
          <StaticImage
            src="../images/logo-color.png"
            alt="Logo"
            objectFit="scale-down"
            placeholder="none"
            className={styles.ColorLogo}
          />
          <div className={styles.Info}>
            <div className={styles.Heading}>Is a professional managing your estate?</div>
            <div className={styles.Description}>
              MKW Consulting Limited(“MKW”), prides itself in providing professional advice in
              establishing the right estate planning vehicles for discerning individuals and their
              beneficiaries.
            </div>
            <div className={styles.ButtonContainer}>
              <AnchorLink to="/#contactus" title="ContactUsButton" className={styles.Button}>
                <span>Contact Us</span>
              </AnchorLink>
              {/* <a className={styles.Button} href="/#contactus">
                Contact Us
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
