// extracted by mini-css-extract-plugin
export var Button = "HeroSection-module--Button--jV3Yw";
export var ButtonContainer = "HeroSection-module--ButtonContainer--JE2pP";
export var ColorLogo = "HeroSection-module--ColorLogo--P8mKt";
export var Container = "HeroSection-module--Container--7pcaN";
export var Description = "HeroSection-module--Description--SUgvF";
export var Heading = "HeroSection-module--Heading--X-+xb";
export var Info = "HeroSection-module--Info--NKxOt";
export var Overlay = "HeroSection-module--Overlay--378xe";
export var Pane = "HeroSection-module--Pane--PTcB1";
export var fadeLeft = "HeroSection-module--fadeLeft--wPhqz";
export var fadeRight = "HeroSection-module--fadeRight--9qqlV";