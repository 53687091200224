import { faHouseChimney, faLineChart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";
import * as styles from "./Services.module.scss";

// Fix huge icon flash: https://github.com/FortAwesome/react-fontawesome/issues/234
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

const Services = () => {
  return (
    <section className={styles.Container} id="services">
      <h2 className={styles.Heading}>~ Services ~</h2>
      <div className={styles.Offerings}>
        <div className={styles.Offering}>
          <FontAwesomeIcon icon={faLineChart} className={styles.Icon} />
          <div className={styles.Title}>Estate Planning</div>
          <div className={styles.Description}>
            At MKW Consulting, our experienced estate planning consultants, assist clients who
            require comprehensive advice to maximize the value of their estates…
          </div>
          <div className={styles.ButtonContainer}>
            <AnchorLink to="/#estateplanning" title="EstatePlanning" className={styles.Button}>
              <span>Learn More</span>
            </AnchorLink>
          </div>
        </div>
        <div className={styles.Offering}>
          <FontAwesomeIcon icon={faHouseChimney} className={styles.Icon} />
          <div className={styles.Title}>Real Estate Services</div>
          <div className={styles.Description}>
            At MKW Consulting we advise clients on real estate projects. We believe in getting
            things done properly for our clients which means professionally…
          </div>
          <div className={styles.ButtonContainer}>
            <AnchorLink
              to="/#realestateservices"
              title="RealEstateServices"
              className={styles.Button}
            >
              <span>Learn More</span>
            </AnchorLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
